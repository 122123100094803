<template>
  <v-row class="match-height">
    <v-snackbar
      v-model="notificaciones.snackbar"
      :bottom="notificaciones.y === 'bottom'"
      :color="notificaciones.color"
      :left="notificaciones.x === 'left'"
      :multi-line="notificaciones.mode === 'multi-line'"
      :right="notificaciones.x === 'right'"
      :timeout="notificaciones.timeout"
      :top="notificaciones.y === 'top'"
      :vertical="notificaciones.mode === 'vertical'"
    >
      {{ notificaciones.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          v-bind="attrs"
          @click="notificaciones.snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-col cols="12">
      <Breadcrumbs :items="items" />
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title>{{ formTitulo }}</v-card-title>
        <v-card-text>
          <v-form
            id="form"
            ref="form"
            v-model="isValid"
            class="multi-col-validation"
            :loading="loadingSaveForm"
            lazy-validation
          >
            <v-row>
              <!-- Nombre -->
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="name"
                  label="Nombre"
                  outlined
                  dense
                  placeholder="Nombre"
                  :rules="[
                    (v) => !!v || 'El campo nombre es obligatorio',
                    (v) => (!!v && v.length <= 70) || 'El campo nombre admite hasta 70 caracteres',
                  ]"
                  counter="70"
                  maxlength="70"
                  required
                  error-count="2"
                  :error="campos.name.error"
                  :error-messages="errorMsgName"
                >
                  <template #label>
                    <app-label
                      field="Nombre"
                      required="true"
                    />
                  </template>
                </v-text-field>
              </v-col>
              <!-- Description -->
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="description"
                  label="Descripción"
                  outlined
                  dense
                  placeholder="Descripción"
                  :rules="[
                    (v) => !!v || 'El campo descripción es obligatorio',
                    (v) => (!!v && v.length <= 150) || 'El campo descripción hasta 150 caracteres',
                  ]"
                  counter="150"
                  maxlength="150"
                  required
                  error-count="2"
                  :error="campos.description.error"
                  :error-messages="errorMsgDescription"
                >
                  <template #label>
                    <app-label
                      field="Descripción"
                      required="true"
                    />
                  </template>
                </v-text-field>
              </v-col>
              <!-- Permisos -->
              <v-col
                cols="12"
                md="12"
              >
                <DualList
                  v-model="profilePermissions"
                  :items="listaPermisos"
                  item-text="description"
                  item-value="id"
                  :mobile-breakpoint="mobileBreakpoint"
                  :messages="messages"
                  :icons="icons"
                  :height="350"
                ></DualList>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                class="mt-6"
              >
                <v-btn
                  color="primary"
                  :disabled="!isValid"
                  :loading="loadingSaveForm"
                  @click="saveForm"
                >
                  Enviar
                </v-btn>
                <v-btn
                  type="reset"
                  outlined
                  class="mx-2"
                  @click="cancelarForm"
                >
                  Cancelar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { ref, reactive, computed } from '@vue/composition-api'
import {
  mdiChevronDoubleDown,
  mdiChevronDown,
  mdiChevronDoubleUp,
  mdiChevronUp,
  mdiChevronDoubleRight,
  mdiChevronRight,
  mdiChevronDoubleLeft,
  mdiChevronLeft,
} from '@mdi/js'
import moment from 'moment'
import { useRouter } from '@/utils'
import HTTP from '@/utils/axios/axios-config-base'
import AppLabel from '@/components/field/Label'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import DualList from '@/components/DualList/DualList.vue'

export default {
  components: { AppLabel, Breadcrumbs, DualList },
  computed: {
    formatDate() {
      return this.publicationDate ? moment(this.publicationDate).format('DD-MM-YYYY') : ''
    },
  },
  created() {
    if (this.$route.params.obj != null) {
      this.obj.id = this.$route.params.obj.id
      this.obj.name = this.$route.params.obj.name
      this.obj.description = this.$route.params.obj.description
      this.obj.profilePermissions = this.$route.params.obj.profilePermissions
      this.name = this.$route.params.obj.name
      this.description = this.$route.params.obj.description
      this.profilePermissions = this.$route.params.obj.profilePermissions
      this.formTitulo = this.$route.params.formTitulo
      this.isValid = true
      this.items = this.$route.params.items
      this.profileById(this.obj)
    }
  },
  setup() {
    const { router } = useRouter()
    const formTitulo = ref('Crear perfil')
    const loadingSaveForm = ref(false)
    const name = ref('')
    const description = ref('')
    const profilePermissions = ref([])
    const obj = reactive({
      id: null,
      name: null,
      description: null,
      profilePermissions: null,
    })
    const form = ref(null)
    const isValid = ref(true)
    const items = reactive([
      {
        text: 'Inicio',
        disabled: false,
        href: '/',
      },
      {
        text: 'Perfiles',
        disabled: false,
        href: '/profile',
      },
      {
        text: `${formTitulo.value}`,
        disabled: true,
        href: '/profile/form',
      },
    ])
    const listaPermisos = ref([])
    const campos = reactive({
      name: {
        error: false,
        mensajesError: [],
      },
      description: {
        error: false,
        mensajesError: [],
      },
      profilePermissions: {
        error: false,
        mensajesError: [],
      },
    })
    const mobileBreakpoint = 960
    const dualListaltura = 'auto'
    const messages = {
      listOptions: 'Perfiles',
      listSelected: 'Perfiles seleccionados',
      forwardAll: 'Pasar todos los elementos',
      forward: 'Pasar el elemento seleccionado',
      backwardAll: 'Regresar todos los elementos seleccionados',
      backward: 'Regresar el elemento seleccionado',
      find: 'Buscar',
    }
    const icons = {
      mobile: {
        forwardAll: mdiChevronDoubleDown,
        forward: mdiChevronDown,
        backwardAll: mdiChevronDoubleUp,
        backward: mdiChevronUp,
      },
      desktop: {
        forwardAll: mdiChevronDoubleRight,
        forward: mdiChevronRight,
        backwardAll: mdiChevronDoubleLeft,
        backward: mdiChevronLeft,
      },
    }
    const errorMsgName = computed(() => (campos.name.error ? campos.name.mensajesError : []))
    const errorMsgDescription = computed(() => (campos.description.error ? campos.description.mensajesError : []))
    const errorMsgProfilePermissions = computed(() => (campos.profilePermissions.error ? campos.profilePermissions.mensajesError : []))
    const permisosAll = async () => {
      const URL = `${HTTP.defaults.baseURL}identity-api/Profile/permission`
      const resp = await HTTP.get(URL, {})
      listaPermisos.value = []
      if (resp.data.value.length > 0) {
        loadingSaveForm.value = true
        for (let index = 0; index < resp.data.value.length; index += 1) {
          const obj = {
            ...resp.data.value[index],
          }
          listaPermisos.value.push(obj)
        }
        loadingSaveForm.value = false
      }
    }
    const profileById = async obj => {
      await permisosAll()
      if (obj.id !== null) {
        loadingSaveForm.value = true
        const URL = `${HTTP.defaults.baseURL}identity-api/Profile/${obj.id}`
        const resp = await HTTP.get(URL)
        if (resp.status === 200) {
          if (resp.data) {
            const { data } = resp
            obj.name = data.name
            obj.description = data.description
            name.value = data.name
            description.value = data.description
            const permissoAll = []
            for (let index = 0; index < data.permissions.length; index += 1) {
              permissoAll.push(data.permissions[index])
            }
            obj.profilePermissions = permissoAll
            profilePermissions.value = permissoAll
          }
        }
        loadingSaveForm.value = false
      }
    }
    const isValidName = s => {
      if (s === '') return true
      const regexp = /^[A-Z]+([\\ ]{0,1}[A-Za-z]+)*$/

      return regexp.test(s)
    }
    permisosAll()
    const notificaciones = reactive({
      snackbar: false,
      color: '',
      mode: '',
      text: '',
      timeout: 3000,
      x: null,
      y: 'top',
    })

    return {
      menu: ref(false),
      formTitulo,
      loadingSaveForm,
      name,
      description,
      profilePermissions,
      obj,
      form,
      isValid,
      items,
      listaPermisos,
      campos,
      mobileBreakpoint,
      dualListaltura,
      messages,
      icons,
      errorMsgName,
      errorMsgDescription,
      errorMsgProfilePermissions,
      profileById,
      notificaciones,
      isValidName,
      saveForm: async () => {
        if (loadingSaveForm.value) return
        if (isValid && form.value.validate()) {
          loadingSaveForm.value = true
          campos.value = reactive({
            name: {
              error: false,
              mensajesError: [],
            },
            description: {
              error: false,
              mensajesError: [],
            },
            profilePermissions: {
              error: false,
              mensajesError: [],
            },
          })

          try {
            const url = obj.id == null ? `${HTTP.defaults.baseURL}identity-api/Profile` : `${HTTP.defaults.baseURL}identity-api/Profile/${obj.id}`
            const objeto = {}
            objeto.name = name.value
            objeto.description = description.value
            const arrayPermisos = []
            for (let i = 0; i < profilePermissions.value.length; i += 1) {
              arrayPermisos.push(profilePermissions.value[i].id)
            }
            objeto.profilePermissions = arrayPermisos
            const resp = obj.id == null ? await HTTP.post(url, objeto) : await HTTP.patch(url, objeto)
            if (resp.status === 200) {
              notificaciones.color = 'success'
              notificaciones.text = obj.id == null ? 'El registro ha sido creado satisfactoriamente' : 'El registro ha sido actualizado satisfactoriamente'
              notificaciones.snackbar = true
              loadingSaveForm.value = false
              router.push({
                name: 'profile',
                params: {
                  notificaciones: {
                    snackbar: notificaciones.snackbar,
                    text: notificaciones.text,
                    color: notificaciones.color,
                  },
                },
              })
            }
          } catch (error) {
            const { errors, title } = error.response.data
            if (errors) {
              let errorDialog = true
              if (errors.Name) {
                for (let index = 0; index < errors.Name.length; index += 1) {
                  campos.name.mensajesError.push(errors.Name[index])
                }
                errorDialog = false
                campos.name.error = true
              }
              if (errors.Description) {
                for (let index = 0; index < errors.Description.length; index += 1) {
                  campos.description.mensajesError.push(errors.Description[index])
                }
                errorDialog = false
                campos.description.error = true
              }
              if (errors.ProfilePermissions) {
                for (let index = 0; index < errors.ProfilePermissions.length; index += 1) {
                  campos.profilePermissions.mensajesError.push(errors.ProfilePermissions[index])
                }
                errorDialog = false
                campos.profilePermissions.error = true
              }
              if (errorDialog) {
                let error = ''
                const keysError = Object.keys(errors)
                for (let index = 0; index < keysError.length; index += 1) {
                  for (let index1 = 0; index1 < errors[keysError[index]].length; index1 += 1) {
                    error += errors[keysError[index]][index1]
                  }
                }
                notificaciones.snackbar = true
                notificaciones.color = 'error'
                notificaciones.text = error
              }
            }
            loadingSaveForm.value = false
          }
        }
      },
      cancelarForm: () => {
        router.push({ name: 'profile' })
      },
    }
  },
}
</script>
<style>
</style>
